import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import "./style.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React,{ useContext, useEffect,useState } from "react";
import { AuthContext } from "./context/AuthContext";
import { auth,db } from "./firebase";
import { doc,setDoc,getDoc,updateDoc,serverTimestamp } from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";

function App() {
  const [err, setErr] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search)
  const from = queryParameters.get("from")
  const to = queryParameters.get("to")
  const { currentUser } = useContext(AuthContext);
  const[loader,setLoader]=useState(false);

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
       window.location.reload(false);
    }

    return children
  };

  const handleSelect = async (userDetail) => {

    //check whether the group(chats in firestore) exists, if not create
  if(to!==null){
    const combinedId =
    userDetail.from.uid.toString() > userDetail.to.uid.toString()
        ? userDetail.from.uid.toString() + userDetail.to.uid.toString()
        : userDetail.to.uid.toString() + userDetail.from.uid.toString();
   
    try {
      const res = await getDoc(doc(db, "chats", combinedId));

      if (!res.exists()) {
        //create a chat in chats collection
        await setDoc(doc(db, "chats", combinedId), { messages: [] });

        //create user chats
        await updateDoc(doc(db, "userChats", userDetail.from.uid.toString()), {
          [combinedId + ".userInfo"]: {
            uid: userDetail.to.uid.toString(),
            displayName: userDetail.to.displayName.toString(),
            photoURL: userDetail.to.photoURL.toString(),
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", userDetail.to.uid.toString()), {
          [combinedId + ".userInfo"]: {
            uid: userDetail.from.uid.toString(),
            displayName: userDetail.from.displayName.toString(),
            photoURL: userDetail.from.photoURL.toString(),
          },
          [combinedId + ".date"]: serverTimestamp(),
        });
      }
    } 
    catch (err) {}
  }

  };


useEffect(()=>{
  const fetchTodos = async () => {
   let  url = 'https://iboatel.com/api/chatwithdetails?from='+from;
   if(to!==null){
    url = 'https://iboatel.com/api/chatwithdetails?from='+from+'&to='+to;
   }
    try {
      const response = await fetch(url);
      const data = await response.json();
     const jsonArray = JSON.stringify(data);
     localStorage.setItem('fromOrTo', jsonArray);
     handleSelect(data.data)

     try {
      await signInWithEmailAndPassword(auth, data.data.from.email, '12345678');
      return <Navigate to="/" />;
    } catch (err) {
      setErr(true);
    }

     

    } catch (error) {
      console.log(error);
    }
  };
  fetchTodos();
},[]);

setTimeout(() => {
  setLoader(true);
}, 3000);

if(!loader){
return(

  <div className="loader"></div>

)
}else{
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
}

export default App;
