import React,{useEffect} from 'react'
import Sidebar from '../components/Sidebar'
import Chat from '../components/Chat'

const Home = () => {

  useEffect(() => {
    return () => {

       window.addEventListener("beforeunload", function(e) {

      let confirmationMessage = "o/";

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE

      alert('hi');
      return confirmationMessage; //Webkit, Safari, Chrome
    
    });
   }
   
  });

  return (
    <div className='home'>
      <div className="container">
        <Sidebar/>
        <Chat/>
      </div>
    </div>
  )
}

export default Home