import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDG1YL74fZXF5a_cixtA05-STTYhXVh1-o",
  authDomain: "iboatel.firebaseapp.com",
  projectId: "iboatel",
  storageBucket: "iboatel.appspot.com",
  messagingSenderId: "383163880269",
  appId: "1:383163880269:web:d4c2e2975d6b8f3e84dd04",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()
